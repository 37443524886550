import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/Layout.js"
import { AboveFold, BelowFold, Section } from "../components/section/Section.js"
import { Row } from "../components/row/Row"
import {
  Title,
  SubHeading,
  Heading,
  Subtitle,
} from "../components/title/Title.js"
import { Column33, Column50 } from "../components/column/Column.js"
import { PrimaryButton, ButtonContainer } from "../components/button/Button.js"
import featuredImage from "../static/undraw_shared_workspace_hwky.png"
import imgAuth from "../static/authors/andreeamacoveiciuc.jpeg"
import { CourseSnippet } from "../components/courseSnippet/CourseSnippet"
import Cta from "../components/ctablock/Cta"
import imgServ1 from "../static/undraw_apps_m7mh.png"
import imgServ2 from "../static/undraw_software_engineer_lvl5.png"
import imgServ3 from "../static/undraw_creativity_wqmm.png"
import { TextMedium } from "../components/text/Text.js"
import { Link } from "../components/link/Link.js"
import imgServ4 from "../static/undraw_dashboard_nklg.png"
import imgServ5 from "../static/undraw_friends_online_klj6.png"
import imgServ7 from "../static/undraw_remotely_2j6y.png"
import imgServ10 from "../static/undraw_mobile_testing_reah.png"
import imgServ11 from "../static/undraw_modern_professional_yt9h.png"
import {
  Image,
  ImageNarrow,
  ImageContainer,
  ImageContainerSmall,
  ImageSmall,
  ImageHome
} from "../components/image/Image.js"
import Seo from "../components/seo"
import Trusted  from "../blocks/Trusted.js"
import { Testimonials } from "../components/testimonial/Testimonial.js"


function Learning({ children, isCentered, ...rest }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      allContentfulTraining(limit: 3, sort: { fields: date, order: ASC }) {
        edges {
          node {
            id
            courseBanner {
              gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
              )
              id
              title
              file {
                url
              }
            }
            courseContent {
              childMarkdownRemark {
                html
              }
            }
            date(formatString: "Do MMMM, YYYY")
            duration
            price
            level
            title
            subtitle
            slug
            courseLink
          }
        }
      }
    }
  `)

  // const imageBanner = getImage(data.allContentfulTraining.courseBanner) //added new testing 

  return (
    <Layout>
      <Seo title="Learn Content Strategy" 
       description="We offer training, courses and e-books for all levels, from beginner to advanced. See our upcoming sessions"
      
       image={featuredImage}
       featuredImage={featuredImage} 
      />

    <AboveFold isCentered>
        <Row isCentered>
          <Column50>
            <Title isPrimary title="Become a Content Strategy Pro" />
            <SubHeading
              subheading="We offer courses for all levels, from beginner to advanced."
            />
            <ButtonContainer>
              <PrimaryButton buttonText="See courses" buttonLink="/content-strategy-training/" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <ImageHome src={featuredImage} alt="homepage" />
          </Column50>
        </Row>
      </AboveFold>

      <Trusted />

      <Section isCentered>
        <Subtitle isPrimary subtitle="Choose your preferred learning method" />
        <Row isCentered>
          <Column50>
            <ImageContainer>
              <ImageNarrow src={imgServ5} alt="" />
            </ImageContainer>
          </Column50>
          <Column50 isFloating>
            <Heading isCentered heading="On-demand videos" />
            <TextMedium
              textMedium="For those of you who prefer interactive learning, we offer on-demand video courses for beginner, 
              intermediate, and advanced levels."
            />
            <TextMedium
              textMedium="Our trainings are suitable for content and online marketing professionals, as well as for 
              business owners and startup founders who want to learn content strategy."
            />
            <Link
              href="/content-strategy-training/"
              anchortext="See courses"
              isCentered
              isPrimary
            />
          </Column50>
        </Row>
        <Row isCentered>
          <Column50 isFloating>
            <Heading isCentered heading="Templates and toolkits" />
            <TextMedium
              textMedium="Get the full picture of a Content strategist's job and learn all the fundamental 
              concepts, methodologies, and frameworks used while developing content strategies in the real world."
            />
            <TextMedium
              textMedium="Our templates and toolkits offer clear, hands-on advice, and take into consideration the 
              challenges faced by marketing departments in SaaS startups and scaleups."
            />
            <Link href="/content-strategy-templates/" anchortext="See toolkits" isCentered isPrimary />
          </Column50>
          <Column50>
            <ImageContainer>
              <ImageNarrow src={imgServ7} alt="" />
            </ImageContainer>
          </Column50>
        </Row> 
      </Section>

      <BelowFold isCentered isLight>
        <Row isCentered>
        <Subtitle isPrimary isCentered subtitle="How are our courses for?" />
        </Row>
        <Row isCentered>
          <Column33 isFloating>
            <ImageContainerSmall>
              <ImageSmall src={imgServ1} alt="" />
            </ImageContainerSmall>
            <Heading isCentered heading="Content marketers" />
            <TextMedium
              textMedium="You're doing content writing or marketing and want to switch to a more strategic position within
               your company, or you want to learn how to develop and implement a content strategy."
            />
          </Column33>
          <Column33 isFloating>
            <ImageContainerSmall>
              <ImageSmall src={imgServ2} alt="" />
            </ImageContainerSmall>
            <Heading isCentered heading="Digital marketers" />
            <TextMedium
              textMedium="Your background is in paid ads, marketing automation, SEO or social media, and you want to understand
               what content strategy is and how it works with the other channels."
            />
          </Column33>
          <Column33 isFloating>
            <ImageContainerSmall>
              <ImageSmall src={imgServ3} alt="" />
            </ImageContainerSmall>
            <Heading isCentered heading="UX designers" />
            <TextMedium
              textMedium="You're responsible for user research and prototyping, and want to get a full understanding of 
              how content should be mapped to the user journey."
            />
          </Column33>
        </Row>
        <Row isCentered>
          <Column33 isFloating>
            <ImageContainerSmall>
              <ImageSmall src={imgServ4} alt="" />
            </ImageContainerSmall>
            <Heading isCentered heading="Product owners" />
            <TextMedium
              textMedium="You're responsible for defining the vision and roadmap for your product, and you want 
            to get content right from the beginning."
            />
          </Column33>
          <Column33 isFloating>
            <ImageContainerSmall>
              <ImageSmall src={imgServ11} alt="" />
            </ImageContainerSmall>
            <Heading isCentered heading="Marketing managers" />
            <TextMedium
              textMedium="You want to get the full picture - from governance to strategy and tactics - 
              to be able to coordinate your team."
            />
          </Column33>
          <Column33 isFloating>
            <ImageContainerSmall>
              <ImageSmall src={imgServ10} alt="" />
            </ImageContainerSmall>
            <Heading isCentered heading="Startup founders" />
            <TextMedium
              textMedium="You want to learn the basics so you can do it yourself or to know what to look 
              for when building a content team."
            />
          </Column33>
        </Row>
      </BelowFold>

      <Testimonials/>

      <Cta />
    </Layout>
  )
}

export default Learning
